/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/jqk0hnz.css");

/* font sizing guide
Base of 72px
text-4xl sm:text-5xl lg:text-6xl xl:text-7xl

Base of 51px
text-2xl sm:text-3xl lg:text-5xl

Grey headings
max-w-2xl lg:max-w-4xl xl:max-w-5xl

Base of 40px
text-2xl sm:text-3xl

Base of 36px
text-lg sm:text-2xl lg:text-4xl

Base of 18px
text-sm sm:text-md xl:text-lg

Button
w-32 lg:w-48
*/
.border_radius_4 {
    border-radius: 4px;
}

.border_radius_5 {
    border-radius: 5px;
}

.border_radius_10 {
    border-radius: 10px;
}

* {
    font-family: proxima-nova, sans-serif;
    /* font-size: 400; */
}

.lkbutton {
    color: white;
    text-transform: uppercase;
    padding: 0.72rem 0;
    font-size: 16px;
}

.header_background {
    background-image: linear-gradient(#dddddd, #f7f7f7);
}

.dropdown:hover .dropdown-menu {
    display: block;
}
/* Animations */

p,
button,
a,
img,
div.fade_in {
    animation: transition_in 0.75s;
}

@keyframes transition_in {
    from {
        opacity: 0;
        transform: rotateX(-10deg);
    }

    to {
        opacity: 1;
        transform: rotateX(0);
    }
}

@keyframes translate_up {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 765px) {
    .slick-prev {
        left: 0px !important;
    }
    .slick-next {
        right: 0px !important;
    }
}
